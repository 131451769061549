import { useEffect, useState } from 'react';

const useScrollPercentage = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;
      const scrolled = typeof window !== 'undefined' ? window.scrollY : 0;
      const percentage = (scrolled / documentHeight) * 100;
      setScrollPercentage(percentage);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return Number(scrollPercentage.toFixed(0));
};

export default useScrollPercentage;
