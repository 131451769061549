import { useState, useEffect } from 'react'


const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0
const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: windowWidth,
    height: windowHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return screenSize
}

export default useScreenSize
