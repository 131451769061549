import { useCustomerContext } from '@/context/CustomerContext'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import { useMemo } from 'react'

const useProductDiscountsInfo = (product) => {
  const { customer } = useCustomerContext()

  const isSubscription = product.tags?.includes('Subscription Box')
  const isGiftCard = product.content?.handle === 'digital-gift-card'
  const isAddOn = product.tags?.includes('Add-On')
  const isSwap = product.tags?.includes('Swap')
  const isMealKit = product.tags?.includes('Meal Kit')

  const { getEligibleDiscounts } = useHeadlessCheckoutContext()

  const [eligibleDiscounts] = useMemo(
    () => getEligibleDiscounts({ lineItems: [{ ...product }] }),
    [product, customer],
  )

  const hasApplicableDiscount = !!eligibleDiscounts
  const discountEnabledProduct =
    !isGiftCard && !isSubscription && !isAddOn && !isSwap && !isMealKit

  const hasApplicableCustomerDiscount =
    hasApplicableDiscount && customer && discountEnabledProduct
  const hasApplicableNonCustomerDiscount =
    hasApplicableDiscount && !customer && discountEnabledProduct

  return {
    hasApplicableDiscount,
    discountEnabledProduct,
    hasApplicableCustomerDiscount,
    hasApplicableNonCustomerDiscount,
  }
}

export default useProductDiscountsInfo
