import classes from './ProductCollectionCard.module.scss'
import classNames from 'classnames'
import ImageReveal from '@/components/ImageReveal'
import { dataLayerSelectProduct } from '@/utils/dataLayer'
import { useRouter } from 'next/router'
import { useState, useMemo, useCallback } from 'react'
import ReviewStarsWithCounter from '@/components/Review/ReviewStarsWithCounter/ReviewStarsWithCounter'
import useProductReviewsDetails from '@/hooks/useProductReviewsDetails'
import { usePDPDrawerContext } from '@/context/PDPDrawerContext'
import ProductHeroPricing from '@/components/Sections/ProductHero/ProductHeroPricing'
import { InfoOutlined } from '@mui/icons-material'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@/services/sanityClient'

function ProductCollectionCard({
  title,
  description,
  images,
  as = 'list-item',
  cardType = 'default',
  location,
  hoverFeature,
  product,
  useFlyout,
  usePricing,
  useATC,
  sanityImages,
}) {
  const reviewsInfo = useProductReviewsDetails(product?.handle)
  const { openProductDetailDrawer } = usePDPDrawerContext()
  const { addItemToOrder } = useHeadlessCheckoutContext()

  const mapImage = useCallback(
    (productImage) => {
      const builder = imageUrlBuilder(sanityClient)
      return builder
        .image(productImage.asset.url)
        .width(1000)
        .height(1000)
        .focalPoint(
          productImage?.hotspot?.x || 0.5,
          productImage?.hotspot?.y || 0.5,
        )
        .crop('focalpoint')
        .fit('crop')
        .url()
    },
    [images?.edges?.length, sanityImages?.length],
  )

  const [fistImage, secondImage] = useMemo(() => {
    if (!sanityImages?.length) {
      return [
        images?.edges?.[0]?.node.originalSrc,
        images?.edges?.[1]?.node.originalSrc,
      ]
    }

    return sanityImages
      ?.slice(0, 2)
      .map(({ productImage }) => productImage)
      .map(mapImage)
  }, [images?.edges?.length, sanityImages?.length])

  const [onMoveEvent, setOnMoveEvent] = useState(null)
  const router = useRouter()
  const Element = as === 'list-item' ? 'li' : 'div'

  const flagTag = product?.tags?.find((tag) =>
    tag.toLowerCase()?.includes('flag:')
  )
  const splitFlagTag = flagTag?.split(':')[1]

  const onClickMainButton = async () => {
    // If "useATC" adds the product to cart
    if (useATC) {
      await addItemToOrder({
        product,
        variant: product.variants?.[0],
        openFlyout: true,
      })
      return
    }

    if (useFlyout) {
      openProductDetailDrawer({ productData: product })
    } else {
      dataLayerSelectProduct({ product, url: router.pathname })
      router.push(`/products/${encodeURIComponent(product.content.handle)}`)
    }
  }

  const onLearnMoreClick = () => {
    openProductDetailDrawer({ productData: product })
  }

  return (
    <div className={`${classes['wrapper']} wrapper`}>
      {splitFlagTag && (
        <div className={`${classes['best-seller']} best-seller`}>
          {splitFlagTag}
        </div>
      )}
      <Element
        onClick={() => {
          // If flyout is not configured, clicking on the card causes it to open the PDP page
          if (!useATC) {
            onClickMainButton()
          }
        }}
        className={classNames(
          classes['card'],
          cardType === 'hero-main' && classes['card--hero-main'],
          cardType === 'hero-secondary' && classes['card--hero-secondary'],
        )}
        onMouseMove={(event) => setOnMoveEvent(event)}
      >
        {useATC && (
          <button
            aria-label="Learn more about the product"
            className={classes['card__learn-more-btn']}
            onClick={onLearnMoreClick}
          >
            <InfoOutlined style={{ color: '#fff' }} />
          </button>
        )}
        <ImageReveal
          parentOnMove={onMoveEvent}
          firstImage={fistImage}
          secondImage={secondImage}
          hoverFeature={hoverFeature}
        />

        <div className={classes['card__content']}>
          <h3
            className={classNames(
              classes['card__content__title'],
              cardType === 'hero-secondary' &&
                classes['card__content__title--centered'],
            )}
          >
            {title}
            {(cardType === 'hero-main' || cardType === 'hero-secondary') &&
              location && (
                <span className={classes['card__content__location']}>
                  {location}
                </span>
              )}
          </h3>
          {usePricing && (
            <ProductHeroPricing
              product={product}
              selectedVariant={product.variants?.[0]}
              pricingDisplay={product.sanityData?.pricingDisplay}
            />
          )}
          {cardType !== 'hero-secondary' && reviewsInfo && (
            <ReviewStarsWithCounter
              rating={reviewsInfo.average}
              numberOfReviews={reviewsInfo.totalReviews}
            />
          )}

          <span className={classes['card__content__description']}>
            {description}
          </span>
        </div>
        <button onClick={onClickMainButton} className={classes['card__button']}>
          {useATC ? 'Add to Cart' : 'View Details'}
        </button>
      </Element>
    </div>
  )
}

export default ProductCollectionCard
