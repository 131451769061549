import AccordionItem from './AccordionItem'
import { useRouter } from 'next/router'

const Accordion = ({fields}) => {

  const { accordionItems } = fields
  const router = useRouter();

  const { handle } = router.query;

  // Verify if the page is the contact us page
  const isContactUsPage = handle === 'contact-us';

  // Live chat item data had to be hardcoded because the data in sanity was not being fetched for this section specifically

  const handleLiveChat = (e) => {
    if (typeof window !== 'undefined' && typeof window?.zE === 'function') {
      e.preventDefault();
      window.zE('messenger', 'open');
    }
  }

  const liveChatItem = {
    title: (
      <button className="accordion-item__btn"
        onClick={handleLiveChat}
      >
        Live Chat
      </button>
    ),
    image: {
      asset: {
        _type: 'sanity.imageAsset',
        sha1hash: 'be6744f89ca1dd2105531c01128812419da3c5df',
        url: 'https://cdn.sanity.io/images/shh4ldkk/production/ac5eb9d40563dbb41b0d83f826a659f133c913b2-26x26.svg',
        size: 1073,
        extension: 'svg',
      },
      imageBrightness: 100,
      _type: 'imageWithAlt',
    },
    text: [
      {
        _key: 'be2e0b49e51c',
        _type: 'block',
        style: 'normal',
        markDefs: [],
        children: [
          {
            _key: '207c5d3b4d810',
            _type: 'span',
            text: 'Monday–Friday 9am-6pm',
            marks: [],
          },
        ],
      },
    ],
    _key: 'ea93f9e8c5a2',
    _type: 'accordionItem',
  };

  return (
    <ul className="accordion-items">
      {accordionItems.map(item => {
        return <AccordionItem key={item._key} props={item} />
      })}
      {isContactUsPage && <AccordionItem key={liveChatItem._key} props={liveChatItem} />}
    </ul>
  )
}

export default Accordion